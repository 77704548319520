<template>
<div>
    <div class="wrap">
        <form  name="mfrm" @submit.prevent="getCenterList" method="post">
            <div class="con_top">
                <h2><span>■</span> 센터관리</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <select v-model="permission" @change="getCenterList()" class="w-100px">
                                <option value="">승인유무</option>
                                <option value="Y">승인</option>
                                <option value="N">미승인</option>
                            </select>
                            <input type="text" @keyup.enter="getCenterList()" v-model="name" class="w-200px h-40px pd-00 pl-20" placeholder="센터명 검색">
                            <a @click="getCenterList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>
                        <div class="btns mb-30">
                            <a class="btn_new2 pointer" @click="excelDownload()">엑셀 다운로드</a>
                            <a class="btn_new2 pointer" @click="register()">센터등록</a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>센터명</th>
                                <th>주소</th>
                                <th>대표번호</th>
                                <th>휴대폰번호</th>
                                <th>상담서비스</th>
                                <th>승인유무</th>
                            </tr>
                            <tr>
                                <td colspan="4">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>센터명</th>
                                <th>협약센터ID</th>
                                <th>주소</th>
                                <th>대표번호</th>
                                <th>휴대폰번호</th>
                                <th>상담서비스</th>
                                <th>승인유무</th>
                            </tr>
                            <tr v-for="(item, index) of centerList" :key="index">
                                <td>{{ item.rownum }}</td>
                                <td class="underline pointer" @click="detail(item.idx)">{{item.name}}</td>
                                <td>{{item.id}}</td>
                                <td>{{ item.address }}</td>
                                <td>{{ item.mainNumber }}</td>
                                <td>{{ item.phone }}</td>
                                <td>{{ item.consService }}</td>
                                <td>{{formatYN(item.permission)}}</td>
                            </tr>
                            <tr v-if="centerList.length === 0">
                                <td colspan="4">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import FileSaver from 'file-saver'
export default {
    data: () => ({
        name: '', // 센터명 검색 text
        centerList: [], // 센터 리스트
        

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        permission: '',
        loading:true,
    }),

    mounted() {

        if(sessionStorage.getItem('origin_center_pageNum') !== 'undefined' && sessionStorage.getItem('origin_center_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_center_pageNum'))
        }
        if(sessionStorage.getItem('origin_center_name') !== 'undefined' && sessionStorage.getItem('origin_center_name') !== null) {
            this.name = sessionStorage.getItem('origin_center_name')
        }
        if(sessionStorage.getItem('origin_center_permission') !== 'undefined' && sessionStorage.getItem('origin_center_permission') !== null) {
            this.permission = sessionStorage.getItem('origin_center_permission')
        }

        this.getCenterList()
    },

    methods: {

        //센터 관리 엑셀다운로드
        excelDownload(){
            let headers = {
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            }

            let params = {
                name : this.name,
                permission : this.permission
            }
            this.axios.defaults.headers.post[headers]
            this.axios.get('/api/v1/excel/centerManagement-list', {
                params: params,
                responseType: 'arraybuffer'
            }).then(res => {
                FileSaver.saveAs(new Blob([res.data]), "center_list_excel.xlsx")
            }).catch(err => {
                console.log(err)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },
        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getCenterList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getCenterList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getCenterList()
            }
        },

        // 센터 리스트 api 호출
        getCenterList() {
            var params = {
                name: this.name,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                permission: this.permission
            }

            sessionStorage.setItem('origin_center_pageNum', this.pageNum)
            sessionStorage.setItem('origin_center_name', this.name)
            sessionStorage.setItem('origin_center_permission', this.permission)
            this.loading = true;
            this.axios.get('/api/v1/center/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                        
                        if (res.data.centerListCount) {
                            this.listLength = res.data.centerListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)

                    } else {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    console.log(err)
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('id')
                    alert('세션이 만료되어 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                }).finally(()=>{
                    this.loading = false;
                });
        },

        // 센터등록 페이지로 이동
        register() {
            this.$router.push('/origin/center_manage_register')
        },

        // 센터수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/center_manage_modify?idx=${idx}`)
        },

        // 센터정보 상세보기 페이지로 이동
        detail(idx) {
            this.$router.push(`/origin/center_manage_info?idx=${idx}`)
        },

        // 센터 삭제
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                var params = {
                    idx: idx
                }
                this.axios.delete(`/api/v1/center/${idx}`, {
                        params: params
                    })
                    .then(res => {
                        
                        if (res.data.err === 0) {
                            alert('삭제되었습니다.')
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        alert(err)
                    })
            } else {
                return false
            }

        },

        // Y/N 을 승인 비승인으로 출력
        formatYN(flag) {
            if (flag === 'Y') {
                return '승인'
            } else {
                return '미승인'
            }
        }
    }
}
</script>
